<template>
  <v-select
    :v-model="selectedStage"
    :value="value"
    @input="onInput"
    @change="onChange"
    v-bind="$attrs"
    v-on="$listeners"
    :items="uniqueStages"
    item-value="id"
    item-text="name"
    clearable
    return-object
  >
  </v-select>
</template>

<script>
import ApiService from "../../services/api.service";

export default {
  name: "ClaimStageField",
  props: {
    value: Object,
    claimType: Object,
    claimTypes: Array
  },
  data: () => ({
    claimStages: [],
    uniqueStages: [],
    selectedStages: [],
    selectedStage: ""
  }),
  watch: {
    claimType: {
      deep: true,
      handler() {
        this.fetchData();
      }
    },
    claimStages: {
      handler() {
        this.uniqueStages = this.claimStages.filter((value, index) => {
          return this.claimStages.findIndex(elem => elem.name == value.name) == index;
        });
        this.uniqueStages.sort(function(a, b) {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        });
      }
    }
  },
  methods: {
    onInput(obj) {
      this.$emit("input", obj);
    },
    onChange(obj) {
      this.selectedStages = [];
      this.claimStages.forEach(elem => {
        if (elem.name === obj.name) {
          this.selectedStages = this.selectedStages.concat(elem);
        }
      });

      this.$emit("selectedStages", this.selectedStages);
    },
    fetchData() {
      if (this.claimType && this.claimType.id) {
        ApiService.get("/api/claimStages/byClaimTypeId/" + this.claimType.id + "?sort=rankOrder,ASC&size=100").then(
          ({ data }) => {
            this.claimStages = data.content;
          }
        );
      }

      if (this.claimTypes) {
        this.claimTypes.forEach(claimT => {
          if (claimT && claimT.id) {
            ApiService.get("/api/claimStages/byClaimTypeId/" + claimT.id + "?sort=rankOrder,ASC&size=100").then(
              ({ data }) => {
                let trimmed = data.content.map(elem => {
                  elem.name = elem.name.trim();
                  return elem;
                });
                this.claimStages = this.claimStages.concat(trimmed);
              }
            );
          }
        });
      }
    }
  },
  mounted() {
    this.fetchData();
  }
};
</script>
