<template>
  <v-select
    v-model="selectedAwardVehicle"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
    :items="items"
    item-value="id"
    item-text="name"
    :clearable="clearable"
    return-object
  >
    <template v-slot:selection="data"> {{ data.item.name }} <br /> </template>
  </v-select>
</template>

<script>
import ApiService from "../../services/api.service";

export default {
  name: "AwardVehicleField",
  props: {
    value: Object,
    available: Array,
    awardVehicles: {
      default: () => [],
      type: Array
    },
    valueId: Number,
    clearable: {
      type: Boolean,
      default: true
    },
    awardVehicleTypes: {
      default: () => null,
      type: Array
    },
    country: {
      default: () => null,
      type: Object
    },
    onlyForceable: {
      default: () => false,
      type: Boolean
    },
    status: { type: String, required: false }
  },
  data() {
    return {
      items: [],
      selectedAwardVehicle: undefined
    };
  },
  methods: {
    onInput(obj) {
      this.$emit("input", obj);
    },
    setAwardVehicleById() {
      this.selectedAwardVehicle = this.items.find(elem => elem.id == this.valueId);
      this.$emit("input", this.selectedAwardVehicle);
    },
    fetchData() {
      let filterByEffective = true;

      if (this.status && this.status == "Inactive") {
        filterByEffective = false;
      }
      if (this.status && this.status == "All") {
        filterByEffective = undefined;
      }

      if (this.available) {
        this.items = this.available;
        if (this.awardVehicleTypes) {
          this.items = this.awardVehicles.filter(av =>
            this.awardVehicleTypes.filter(providedAv => providedAv.name == av.awardVehicleType.name)
          );
        }
        if (this.onlyForceable) {
          this.items = this.items.filter(item => item.awardVehicleType.forceable == true);
        }
        if (this.status != "All") {
          this.items = this.items.filter(item => item.effective == filterByEffective);
        }
        if (this.valueId) {
          this.setAwardVehicleById();
        }
      } else {
        ApiService.post("/api/awardVehicles/search?size=100", {
          awardVehicleTypes: this.awardVehicleTypes,
          country: this.country,
          isEffective: this.status
        }).then(({ data }) => {
          this.items = data.content;
          if (this.onlyForceable) {
            this.items = this.items.filter(item => item.awardVehicleType.forceable == true);
          }
          if (this.valueId) {
            this.setAwardVehicleById();
          }
        });
      }
    }
  },
  watch: {
    valueId: {
      deep: true,
      immediate: true,
      handler(v) {
        if (v) {
          this.setAwardVehicleById();
        }
      }
    },
    available: {
      deep: true,
      immediate: true,
      handler() {
        this.fetchData();
      }
    },
    awardVehicleTypes: {
      deep: true,
      immediate: true,
      handler() {
        this.fetchData();
      }
    },
    value: {
      immediate: true,
      handler(v) {
        this.selectedAwardVehicle = v;
        this.$nextTick(() => {
          this.$forceUpdate();
        });
      }
    }
  },
  mounted() {
    this.fetchData();
  }
};
</script>
