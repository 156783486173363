<template>
  <v-select :value="selectedItems" v-bind="$attrs" v-on="$listeners" :items="groupedItems">
    <!-- Pass on all named slots -->
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />

    <!-- Pass on all scoped slots -->
    <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope"
      ><slot :name="slot" v-bind="scope"
    /></template>

    <template v-slot:prepend-item>
      <v-list-item ripple @click="toggleFields">
        <v-list-item-action>
          <v-icon :color="selectedItems.length > 0 ? 'indigo darken-4' : ''"> {{ iconFields }} </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Select All
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
  </v-select>
</template>

<script>
export default {
  name: "GroupingSelectAll",
  props: {
    value: Array,
    items: Array,
    selectAll: Boolean
  },
  data: () => ({
    selectedItems: [],
    groupedItems: [],
    pastSelectedItems: []
  }),
  methods: {
    getAllIndexes(arr, val) {
      let arr2 = [...arr];

      var indexes = [],
        i = -1;
      while ((i = arr2.findIndex((elem, index) => elem && elem.text == val.text && index > i)) != -1) {
        indexes.push(i);
        //arr2 = arr2.slice(i + 1);
      }
      return indexes;
    },
    toggleFields() {
      this.$nextTick(() => {
        if (this.allFields) {
          this.selectedItems = [];
        } else {
          this.selectedItems = this.items.slice();
        }
      });
    }
  },
  computed: {
    allFields() {
      return this.selectedItems.length === this.items.length;
    },
    someFields() {
      return this.selectedItems.length > 0 && !this.allFields;
    },
    iconFields() {
      return this.allFields ? "mdi-close-box" : this.someFields ? "mdi-minus-box" : "mdi-checkbox-blank-outline";
    }
  },
  watch: {
    value(v) {
      this.selectedItems = v;
    },
    items(v) {
      if (!v || v.length == 0) {
        return;
      }

      this.groupedItems = [];
      let v2 = [...v];
      v2 = v2.sort((a, b) => {
        // Sorting by ClaimType first and then by field name
        if (a.claimType == b.claimType) {
          if (a.name > b.name) {
            return 1;
          } else {
            return -1;
          }
        } else {
          return a.claimType > b.claimType ? 1 : -1;
        }
      });

      //  Adding headers for new Claim Type
      let currentGroup = v2[0].claimType;

      this.groupedItems.push({ header: currentGroup });

      for (let item of v2) {
        if (item.claimType != currentGroup) {
          currentGroup = item.claimType;
          this.groupedItems.push({ header: currentGroup });
        }
        this.groupedItems.push(item);
      }
      if (this.selectAll) {
        this.selectedItems = this.items.slice();
      }
      this.$emit("loaded");
    },
    selectedItems: {
      handler() {
        // The following logic was needed because vuetify doesn't let you know easily
        // which element was added or removed
        if (this.pastSelectedItems.length < this.selectedItems.length) {
          // new element was added
          let newElement = this.selectedItems.filter(elem => this.pastSelectedItems.indexOf(elem) == -1)[0];
          let repetitions = this.getAllIndexes(this.groupedItems, newElement);
          for (let i of repetitions) {
            if (this.groupedItems[i].value != newElement.value) {
              this.selectedItems.push(this.groupedItems[i]);
            }
          }
        }
        if (this.pastSelectedItems.length > this.selectedItems.length) {
          // one element was removed
          let removedElement = this.pastSelectedItems.filter(
            psi => this.selectedItems.findIndex(si => si.value == psi.value) == -1
          )[0];
          this.selectedItems = this.selectedItems.filter(elem => elem && elem.text != removedElement.text);
        }
        this.pastSelectedItems = this.selectedItems;
        this.$emit("change", this.selectedItems);
        this.$emit("input", this.selectedItems);
      },
      deep: true
    },
    selectAll: {
      immediate: true,
      handler() {
        if (this.selectAll) {
          this.selectedItems = this.items.slice();
        }
      }
    }
  },
  mounted() {
    this.selectedItems = this.value;
  }
};
</script>
