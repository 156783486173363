<template>
  <v-combobox
    :value="selectedItem"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
    :loading="loading"
    :items="items"
    :search-input.sync="search"
    hide-no-data
    item-value="id"
    item-text="name"
    multiple
    clearable
    return-object
    no-filter
    ref="combobox"
    v-if="reload"
  >
    <template v-if="showKey == true" v-slot:selection="data">
      {{ data.item.name }} - {{ data.item.organizationKey }}<br />
    </template>
    <template v-else v-slot:selection="data">
      {{ data.item.name }}
    </template>
    <template v-slot:append-item>
      <v-list-item disabled v-if="showBottomMessage">
        <v-list-item-content>
          <v-list-item-title>
            Type to filter more records...
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item></template
    >
  </v-combobox>
</template>

<script>
import ApiService from "../../services/api.service";

export default {
  name: "OrganizationsField",
  props: {
    value: Array,
    preventIds: Array,
    typeKey: String,
    showKey: {
      type: Boolean,
      default: false,
      required: false
    },
    status: { type: String, required: false }
  },
  data: () => ({
    showBottomMessage: true,
    selectedItem: null,
    loading: false,
    search: null,
    items: [],
    rules: [
      v => {
        if (!!v && !v.id) {
          return "Invalid selection";
        } else {
          return true;
        }
      }
    ],
    reload: true
  }),
  methods: {
    onInput(obj) {
      if (obj && obj.id) {
        this.$emit("input", obj);
      }
    },
    clear() {
      this.reload = false;
      this.$nextTick(() => {
        this.reload = true;
      });
    },
    itemText(item) {
      return this.showKey ? item.name + " - " + item.organizationKey : item.name;
    }
  },
  watch: {
    search(val) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      ApiService.post("/api/organizations/search", {
        keyword: val,
        organizationTypeKey: this.typeKey,
        isEffective: this.status
      }).then(({ data }) => {
        let fullItems = data.content;

        if (this.preventIds && this.preventIds.length > 0) {
          fullItems = fullItems.reduce((acc, cur) => {
            if (this.preventIds.indexOf(cur.id) == -1) {
              acc.push(cur);
            }
            return acc;
          }, []);
        }
        if (fullItems.length > 9) {
          this.items = fullItems.slice(0, 9);
          this.showBottomMessage = true;
        } else {
          this.items = fullItems;
          this.showBottomMessage = false;
        }
        this.loading = false;
      });
    }
  },
  mounted() {
    this.selectedItem = this.value;
    this.search = "";
  }
};
</script>
