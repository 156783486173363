<template>
  <v-select
    v-model="selectedItems"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
    :items="claimTypes"
    item-value="id"
    item-text="name"
    clearable
    multiple
    return-object
  >
    <template v-if="selectAllToggle" v-slot:prepend-item>
      <v-list-item ripple @click="toggleFields">
        <v-list-item-action>
          <v-icon :color="selectedItems.length > 0 ? 'indigo darken-4' : ''">
            {{ iconFields }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Select All
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
    <template v-slot:selection="{ item, index }">
      <span v-if="index === 0" class="grey--text caption"> ({{ selectedItems.length }} claim types selected) </span>
    </template>
  </v-select>
</template>

<script>
import ApiService from "../../services/api.service";

export default {
  name: "ClaimTypesField",
  props: {
    value: Array,
    selectAllToggle: Boolean,
    selectAll: Boolean,
    status: { type: String, required: false }
  },
  data: () => ({
    claimTypes: [],
    selectedItems: []
  }),
  methods: {
    onInput() {
      this.selectedItems.sort((a, b) => (a.name > b.name ? 1 : -1)); // Sorts the selected claim types by name
      this.$emit("input", this.selectedItems);
      this.$emit("change", this.selectedItems);
    },
    toggleFields() {
      this.$nextTick(() => {
        if (this.allFields) {
          this.selectedItems = [];
        } else {
          this.selectedItems = this.claimTypes.slice();
        }
        this.onInput();
      });
    }
  },
  mounted() {
    ApiService.post("/api/claimTypes/search?size=100&sort=name,asc", { isEffective: this.status }).then(({ data }) => {
      this.claimTypes = data.content;
      this.$emit("loaded");
      if (this.selectAll) {
        this.selectedItems = this.claimTypes.slice();
        this.onInput();
      }
    });
  },
  computed: {
    allFields() {
      return this.selectedItems.length === this.claimTypes.length;
    },
    someFields() {
      return this.selectedItems.length > 0 && !this.allFields;
    },
    iconFields() {
      return this.allFields ? "mdi-close-box" : this.someFields ? "mdi-minus-box" : "mdi-checkbox-blank-outline";
    }
  },
  watch: {
    selectAll: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          if (this.selectAll) {
            this.selectedItems = this.claimTypes.slice();
            this.onInput();
          }
        });
      }
    }
  }
};
</script>
