<template>
  <v-select
    v-model="selectedYear"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
    :items="years"
    :clearable="clearable"
  >
    <template v-slot:label>
      <slot name="label">
        Fiscal Year
      </slot>
    </template>
  </v-select>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { SELECT_FISCAL_YEAR } from "../../store/client.module";

export default {
  name: "FiscalYearField",
  props: {
    value: Number,
    client: Object,
    numberOfPastYears: {
      type: Number,
      default: 0
    },
    numberOfFutureYears: {
      type: Number,
      default: 0
    },
    clearable: {
      type: Boolean,
      default: false
    },
    useStore: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    selectedYear: undefined,
    years: []
  }),
  methods: {
    onInput(year) {
      if (year) {
        let fiscalYearStartMoment = moment(
          year + "-" + this.client.fiscalMonth + "-" + this.client.fiscalDay,
          "YYYY-MM-DD"
        );
        let fiscalYearStart = fiscalYearStartMoment.format("YYYY-MM-DDT00:00:00.000Z");
        let fiscalYearEnd = fiscalYearStartMoment
          .add(1, "years")
          .add(-1, "days")
          .format("YYYY-MM-DDT00:00:00.000Z");

        let obj = {
          fiscalYear: year,
          fiscalYearStart: fiscalYearStart,
          fiscalYearEnd: fiscalYearEnd
        };
        if (this.useStore) {
          this.$store.dispatch(SELECT_FISCAL_YEAR, obj);
        }
        this.$emit("input", year);
        this.$emit("inputObj", obj);
      } else {
        return undefined;
      }
    },
    generateYears() {
      this.years = [];
      let currentYear = this.$client.getCurrentFiscalYear(this.selectedClient).fiscalYear;
      let i;
      for (
        i = currentYear - parseInt(this.numberOfPastYears);
        i <= parseInt(currentYear) + parseInt(this.numberOfFutureYears);
        i++
      ) {
        this.years.push(i);
      }
    }
  },
  watch: {
    numberOfFutureYears() {
      this.generateYears();
    },
    numberOfPastYears() {
      this.generateYears();
    },
    value: {
      immediate: true,
      handler(v) {
        if (v) {
          this.selectedYear = v;
        }
      }
    }
  },
  mounted() {
    this.generateYears();
    if (!this.value) {
      let currentFiscalYear;
      if (this.useStore && this.selectedFiscalYear) {
        currentFiscalYear = this.selectedFiscalYear.fiscalYear;
      } else {
        currentFiscalYear = parseInt(moment().year());
        let fiscalYearStartMoment = moment(
          currentFiscalYear + "-" + this.client.fiscalMonth + "-" + this.client.fiscalDay,
          "YYYY-MM-DD"
        );
        if (fiscalYearStartMoment.isAfter(moment())) {
          currentFiscalYear--;
        }
      }
      this.$set(this, "selectedYear", currentFiscalYear);
      this.onInput(currentFiscalYear);
    }
    this.$emit("loaded");
  },
  computed: {
    ...mapGetters(["selectedClient", "selectedFiscalYear"])
  }
};
</script>
