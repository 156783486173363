<template>
  <v-combobox
    v-model="selectedItem"
    @input="onInput($event), sortSelected()"
    v-bind="$attrs"
    v-on="$listeners"
    :loading="loading"
    :items="items"
    :search-input.sync="search"
    hide-no-data
    item-value="id"
    :item-text="itemText"
    clearable
    return-object
    no-filter
    ref="combobox"
    :multiple="multiple"
    v-if="reload"
  >
    <template v-if="showKey == true" v-slot:item="{ item, on, attrs }">
      <v-checkbox
        style="display: inline-block; margin-right:32px; margin-top:12px; margin-bottom:12px; padding-top:0px"
        class="v-input--selection-controls__input"
        :input-value="containsElement(item)"
      />
      <span
        v-bind="attrs"
        style="width: 100%; font-size: 0.8125rem; font-weight: 500; line-height: 1rem; vertical-align: middle; "
      >
        {{ item.promotionKey }} - {{ item.name }}
      </span>
    </template>

    <template v-slot:append-item>
      <v-list-item disabled v-if="showBottomMessage">
        <v-list-item-content>
          <v-list-item-title>
            Type to filter more records...
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item></template
    >
  </v-combobox>
</template>

<script>
import ApiService from "../../services/api.service";

export default {
  name: "PromotionsField",
  props: {
    value: Array,
    promotionId: Number,
    preventIds: Array,
    showKey: {
      type: Boolean,
      default: false,
      required: false
    },
    multiple: { type: Boolean, default: true },
    filters: Object,
    status: { type: String, required: false }
  },
  data: () => ({
    showBottomMessage: true,
    selectedItem: null,
    loading: false,
    search: null,
    items: [],
    rules: [
      v => {
        if (!!v && !v.id) {
          return "Invalid selection";
        } else {
          return true;
        }
      }
    ],
    reload: true
  }),
  methods: {
    sortSelected() {
      this.selectedItem = this.selectedItem.sort((a, b) => a.promotionKey - b.promotionKey);
    },
    sortFetched() {
      this.items = this.items.sort((a, b) => a.promotionKey - b.promotionKey);
    },
    onInput(obj) {
      if (obj && obj.id) {
        this.$emit("input", obj);
      }
    },
    clear() {
      this.reload = false;
      this.$nextTick(() => {
        this.reload = true;
      });
    },
    itemText(item) {
      return this.showKey ? item.promotionKey + " - " + item.name : item.name;
    },
    fetchData() {
      let filters = { keyword: this.search, ...this.filters };
      filters.isEffective = this.status;

      ApiService.post("/api/promotions/search?sort=promotionKey,asc", filters).then(({ data }) => {
        let fullItems = data.content.map(prom => prom.promotion);
        if (this.preventIds && this.preventIds.length > 0) {
          fullItems = fullItems.reduce((acc, cur) => {
            if (this.preventIds.indexOf(cur.id) == -1) {
              acc.push(cur);
            }
            return acc;
          }, []);
        }
        if (fullItems.length > 9) {
          this.items = fullItems.slice(0, 9);
          this.showBottomMessage = true;
        } else {
          this.items = fullItems;
          this.showBottomMessage = false;
        }
        this.loading = false;
      });
    },
    containsElement(element) {
      return this.value.map(elem => elem.promotionKey).includes(element.promotionKey);
    }
  },
  watch: {
    search() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.fetchData();
    },
    filters: {
      deep: true,
      handler() {
        this.fetchData();
      }
    },
    value: {
      deep: true,
      handler() {
        this.selectedItem = this.value;
      }
    }
  },
  mounted() {
    this.selectedItem = this.value;
    this.sortSelected();
    this.search = "";
  }
};
</script>
