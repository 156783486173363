<template>
  <v-select :value="selectedItems" @input="onInput" v-bind="$attrs" v-on="$listeners" :items="items">
    <!-- Pass on all named slots -->
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />

    <!-- Pass on all scoped slots -->
    <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope"
      ><slot :name="slot" v-bind="scope"
    /></template>

    <template v-slot:prepend-item>
      <v-list-item ripple @click="toggleFields">
        <v-list-item-action>
          <v-icon :color="selectedItems.length > 0 ? 'indigo darken-4' : ''">
            {{ iconFields }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Select All
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
  </v-select>
</template>

<script>
export default {
  name: "SelectAll",
  props: {
    value: Array,
    items: Array
  },
  data: () => ({
    selectedItems: []
  }),
  methods: {
    onInput() {
      this.$emit("change", this.selectedItems);
      this.$emit("input", this.selectedItems);
    },
    toggleFields() {
      this.$nextTick(() => {
        if (this.allFields) {
          this.selectedItems = [];
        } else {
          this.selectedItems = this.items.slice();
        }
        this.onInput();
      });
    }
  },
  computed: {
    allFields() {
      return this.selectedItems.length === this.items.length;
    },
    someFields() {
      return this.selectedItems.length > 0 && !this.allFields;
    },
    iconFields() {
      return this.allFields ? "mdi-close-box" : this.someFields ? "mdi-minus-box" : "mdi-checkbox-blank-outline";
    }
  },
  watch: {
    value(v) {
      this.selectedItems = v;
    }
  },
  mounted() {
    this.selectedItems = this.value;
  }
};
</script>
