<template>
  <v-select
    :value="value"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
    :items="promotionTypes"
    item-value="id"
    item-text="name"
    clearable
    multiple
    return-object
  >
  </v-select>
</template>

<script>
import ApiService from "@/gapp-components/services/api.service";

export default {
  name: "PromotionTypesField",
  props: {
    value: Array,
    excludePromotionTypeNames: {
      type: Array,
      default: () => []
    },
    status: { type: String, required: false }
  },
  data: () => ({
    promotionTypes: []
  }),
  methods: {
    onInput(obj) {
      this.$emit("input", obj);
    }
  },
  mounted() {
    let filters = {};
    filters.isEffective = this.status;

    ApiService.post("/api/promotionTypes/search?size=100", filters).then(({ data }) => {
      if (this.excludePromotionTypeNames) {
        this.promotionTypes = data.content;
        let filteredPromotionTypes = data.content.filter(pt => !this.excludePromotionTypeNames.includes(pt.name));
        this.$emit("input", filteredPromotionTypes);
      } else {
        this.promotionTypes = data.content;
      }
      this.$emit("loaded");
    });
  }
};
</script>
